<template>
    <div class="attestation-total" v-if="status === 'success'">
        <div class="attestation-total__box box">
            <table class="attestation-total__table attestation-total__table_striped">
                <colgroup>
                    <col class="attestation-total__table-column attestation-total__table-column_title">
                    <col class="attestation-total__table-column attestation-total__table-column_workers-count">
                    <col class="attestation-total__table-column attestation-total__table-column_validated-percent">
                    <col class="attestation-total__table-column attestation-total__table-column_average-attestation">
                </colgroup>
                <thead>
                    <tr class="attestation-total__table-row attestation-total__table-row_head">
                        <th class="attestation-total__table-cell attestation-total__table-cell_head attestation-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('title')"
                        >
                            <span class="attestation-total__table-value">Источник</span>
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'title' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'title' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="attestation-total__table-cell attestation-total__table-cell_head attestation-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('workers_count')"
                        >
                            <span class="attestation-total__table-value">Число сотрудников</span>
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'workers_count' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'workers_count' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="attestation-total__table-cell attestation-total__table-cell_head attestation-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('validated_percent')"
                        >
                            <span class="attestation-total__table-value">Аттестованых</span>
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'validated_percent' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'validated_percent' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                        <th class="attestation-total__table-cell attestation-total__table-cell_head attestation-total__table-cell_sortable"
                            scope="col"
                            @click="setSorting('average_validation')"
                        >
                            <span class="attestation-total__table-value">Cредняя успеваемость</span>
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'average_validation' && sorting.direction === 'ASC'"
                                icon="chevron-up"
                            />
                            <svg-icon class="attestation-total__table-value attestation-total__table-value_icon"
                                v-if="sorting.field === 'average_validation' && sorting.direction === 'DESC'"
                                icon="chevron-down"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="attestation-total__table-row attestation-total__table-row_body  attestation-total__table-row_pinned">
                        <td class="attestation-total__table-cell attestation-total__table-cell_body attestation-total__table-cell_title">
                            Все подразделения
                        </td>
                        <td class="attestation-total__table-cell attestation-total__table-cell_body">
                            {{totalView.workers_count | intFormat}}
                        </td>
                        <td class="attestation-total__table-cell attestation-total__table-cell_body">
                            {{totalView.validated_percent | percentFloatFormat}}
                        </td>
                        <td class="attestation-total__table-cell attestation-total__table-cell_body">
                            {{totalView.average_validation | percentFloatFormat}}
                        </td>
                    </tr>
                    <tr class="attestation-total__table-row attestation-total__table-row_body"
                        v-for="department in departmentsView" :key="department.id"
                    >
                        <td class="attestation-total__table-cell attestation-total__table-cell_body attestation-total__table-cell_title">
                            <router-link class="link link_red"
                                :to="{ name: 'AttestationDepartment', params: { id: department.id } }"
                                :title='`Смотреть валидацию подразделения "${department.title}"`'>
                                {{department.title}}
                            </router-link>
                        </td>
                        <td class="attestation-total__table-cell attestation-total__table-cell_body">
                            {{calcWorkersTotal(departmentWorkers(department)).workers_count | intFormat}}
                        </td>
                        <td class="attestation-total__table-cell attestation-total__table-cell_body">
                            {{calcWorkersTotal(departmentWorkers(department)).validated_percent | percentFloatFormat}}
                        </td>
                        <td class="attestation-total__table-cell attestation-total__table-cell_body">
                            {{calcWorkersTotal(departmentWorkers(department)).average_validation | percentFloatFormat}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import { isTradePoint } from "@/helpers/departments";
    import { isAdministratorPosition } from "@/helpers/positions";

    export default {
        name: "AttestationTotal",
        props: {
            userAttestationUnits: {
                type: Object,
                required: true
            },
            filters: {
                type: Array,
                required: true
            },
            filterId: {
                type: String,
                required: true
            },
            status: {
                type: String,
                required: true
            },
            isFiltersVisible: {
                type: Boolean,
                required: true
            },
            isReportTotalLinkVisible: {
                type: Boolean,
                required: true
            },
            departments: {
                required: true
            },
            calcWorkersTotal: {
                type: Function,
                required: true
            }
        },
        computed: {
            ...mapState({
                sorting: state => state.attestation.sortings.total
            }),
            departmentsView() {
                return this.departments?.map(department => {
                    department.workers = Object.values(department.workers);
                    return department;
                })?.filter(department => {
                    if (this.filterId === "all") {
                        return true;
                    }

                    return this.isTradePoint(department.id) && department.workers.some(worker => {
                        switch (this.filterId) {
                            case "administrator":
                                return this.isAdministratorPosition(worker.position);
                            case "ordinary":
                                return !this.isAdministratorPosition(worker.position);
                            default:
                                return true;
                        }
                    });
                })?.sort((department1, department2) => {
                    let first = this.sorting.field === "title" ? department1.title : department1.total[this.sorting.field];
                    let second = this.sorting.field === "title" ? department2.title : department2.total[this.sorting.field];

                    switch (this.sorting.field) {
                        case "title":
                            return compareStrings(first, second, this.sorting.direction);
                        default:
                            return compareNumbers(first, second, this.sorting.direction);
                    }
                });
            },
            workersView() {
                return this.departmentsView?.reduce((workers, department) => {
                    return workers.concat(department.workers);
                }, [])?.filter(worker => {
                    switch (this.filterId) {
                        case "administrator":
                            return this.isAdministratorPosition(worker.position);
                        case "ordinary":
                            return !this.isAdministratorPosition(worker.position);
                        default:
                            return true;
                    }
                });
            },
            totalView() {
                return this.calcWorkersTotal(this.workersView);
            }
        },
        methods: {
            ...mapMutations([
                "toggleAttestationFilters",
                "toggleReportTotalLink",
                "setAttestationSortingField",
                "setAttestationSortingDirection"
            ]),
            setSorting(field) {
                if (this.sorting.field === field) {
                    this.setAttestationSortingDirection({
                        table: "total",
                        direction: this.sorting.direction === "ASC" ? "DESC" : "ASC"
                    });
                } else {
                    this.setAttestationSortingField({
                        table: "total",
                        field
                    });
                }
            },
            isTradePoint,
            isAdministratorPosition,
            departmentWorkers(department) {
                return department.workers?.filter(worker => {
                    switch (this.filterId) {
                        case "administrator":
                            return this.isAdministratorPosition(worker.position);
                        case "ordinary":
                            return !this.isAdministratorPosition(worker.position);
                        default:
                            return true;
                    }
                });
            }
        },
        created() {
            if ((this.userAttestationUnits?.ids?.length) === 1) {
                this.$router.push({
                    name: "AttestationDepartment",
                    params:{
                        id: this.userAttestationUnits?.ids[0]
                    }
                });
            } else {
                if (!this.isFiltersVisible) {
                    this.toggleAttestationFilters(true);
                }

                if (this.isReportTotalLinkVisible) {
                    this.toggleReportTotalLink(false);
                }
            }
        }
    }
</script>

<style lang="scss">
    .attestation-total__box {
        padding: 8px 16px 16px;
        overflow-x: auto;

        & + & {
            margin-top: 30px;
        }
    }
    .attestation-total__table {
        width: 100%;
    }
    .attestation-total__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .attestation-total__table-column {
        width: 25%;
    }
    .attestation-total__table-row {
        &_body {
            .attestation-total__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .attestation-total__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .attestation-total__table-value {
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }
    }
</style>
